.cardItem{
    height: fit-content;
    margin-top: 5%;
    margin-left: 35%;
}
/*.formDiv{*/
/*    margin:7%;*/
/*}*/
.reference-company{
    font-weight: 600;
    font-size: 18px;
}
.reference-company-name{
    font-weight: 900;
    font-size: 24px;
}
.user-details-page{
    margin-top: 5% !important;
    padding: 0 20% !important;
}
.user-detail-page-inParent{
    margin-bottom: 20px !important;
}
.teamMetrix-dashboard-heading{
    background-color: #3326AE;
    height: 173px;
    display: flex;
    border-radius: 34px;
    justify-content: space-between;
    padding: 0 30px;
}
.inputFieldsContainer{
    background-color: white;
    border-radius: 30px;
    padding: 4% 6%;
    margin-top: 2%;
}
.inputFieldDesign-userDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.avatar-image-user{
    position: absolute;
    bottom: 0;
    left: 70%;
}


.user-detail-label {
    margin-bottom: 8px;
    font-size: 14px;
}

sup {
    color: red;
    top: -2px;
    font-size: 95%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

.user-detail-inputFields{
    border-radius: 4px;
    border: 1px solid #ccc;
    height: 48px;
    padding: 0 8px;
}

.Fieldxxx {
    width: 45%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
}

.FieldError {
    position: absolute;
    bottom: -6px;
    color: red;
    font-size: 12px;
}

.user-detail-btn {
    padding: 12px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: darkblue;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}


.error-text{
    color: red;
}
.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 999;
}

.loader {
    width: 50px;
    height: 50px;
}
