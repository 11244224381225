body {
  margin: 0 !important;
}

.globalLoader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.marginTop {
  margin-top: 1rem;
}

.marginBottom {
  margin-bottom: 1rem;
}

.loaderOuter {
  background-color: rgba(255,255,255,0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
}


.loader {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}

.loader--hide {
  opacity: 0;
  display: none;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
