.alert-wrapper {
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
}
.alert-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    height: 100%;
    text-align: center;
}

.alert-message {
    width: 52%;
    margin: 10px 0;
    background: #94b6c9;
    padding: 15px;
    border: 1px solid black;
}
.textNotification{
    font-size: 26px;
    font-weight: bolder;
}
.timer-wrapper{
    text-align: -webkit-center;
    margin-top: 2%;
}
.warnMsg{
    font-size: 24px;
    font-weight: bolder;
    color: red;
    margin-top: 10px;
}