.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 29px;
  width: 500px;
  z-index: 100;
  overflow: hidden;
}

.alert-title {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: #dd4d2b;
  padding: 20px 0 10px 0;
  border-radius: 29px 29px 0px 0px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  letter-spacing: 1px;
}
.description {
  display: flex;
  flex-direction: column;
  width: 80%;
}
.image-container-with-blackdom{
  border-radius: 26px;
  height: 82px;
  width: 100%;
  margin: 35px 0 40px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image-container {
  border-radius: 26px;
  height: 82px;
  width: 100%;
  margin: 35px 0 40px 0;
  position: relative;
}
.main-image-with-blackDom{
  height: 100px;
}
.main-image {
  position: absolute;
  width: 130px;
  top: -38%;
  left: 32%;
}
.commitment {
  position: absolute;
  width: 250px;
  top: -59%;
  left: 19%;
}
.description p {
  color: #010101;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 126.1%;
  margin-top: 15px;
}
.emojis {
  align-self: center;
  width: 130px;
  margin-bottom: 10px;
}
.main-image-countdown {
  position: absolute;
  top: 50%;
  left: 48%;
  transform: translate(-50%, -50%);
  font-size: 3em;
  color: white;
  z-index: 1;
}

/* Add any existing styles you have */

.circular-countdown {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: 58%;
  left: 48.3%;
  transform: translate(-50%, -50%);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
