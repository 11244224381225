.OA-details-page{
    margin-top: 3% !important;
    padding: 0 20% !important;
}
.OA-detail-page-inParent{
    margin-bottom: 20px !important;
}
.OAMatrix-dashboard-heading{
    background-color: #3326AE;
    height: 173px;
    display: flex;
    border-radius: 34px;
    justify-content: space-between;
    padding: 0 30px;
}
.OA-avatar-image-user{
    position: absolute;
    bottom: 0;
    left: 70%;
}
.OA-inputFieldsContainer{
    background-color: white;
    border-radius: 30px;
    padding: 4% 6%;
}
.OA-inputFieldDesign-userDetails{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.OA-Fieldxxx {
    width: 45%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
}
.OA-user-detail-label {
    margin-bottom: 8px;
    font-size: 14px;
}
.OA-detail-btn {
    padding: 12px;
    width: 240px;
    border-radius: 4px;
    border: 1px solid #ccc;
    background-color: darkblue;
    color: white;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
}