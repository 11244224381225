.custom-notification-creating{
    display: flex;
    gap: 10px;
}
.custom-notification{
    background: rgb(76, 175, 80);
    color: white;
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 59px;
    width: 300px;
    padding: 4px 0 4px 15px;
}
.blurBackground{
    background-color: transparent !important;
}
.blurBackground{
    box-shadow:none;
}
.notification-icon-design{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(79, 79, 79, 0.23);
    display: flex;
    justify-content: center;
    align-items: center;
    color: green;
}

.icon-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    background: #2196F3;
    color: white;
}

.blurBackground{
    position: absolute;
    top: 21px;
    right: 8px;
    color: white;
    opacity: 1;
}