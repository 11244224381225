@font-face {
    font-family: 'Fredoka';
    src: local('Fredoka'), url(../fonts/FredokaOne_Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'Rajdhani';
    src: local('Rajdhani'), url(../fonts/Rajdhani-Bold.ttf) format('truetype');
}
@font-face {
    font-family: 'Rajdhani-Medium';
    src: local('Rajdhani-Medium'), url(../fonts/Rajdhani-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'OpenSans';
    src: local('OpenSans'), url(../fonts/OpenSans_Regular.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'OpenSansBold';
    src: local('OpenSansBold'), url(../fonts/OpenSans_Bold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'OpenSansSemiBold';
    src: local('OpenSansSemiBold'), url(../fonts/OpenSans_SemiBold.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

@font-face {
    font-family: 'OpenSansItalic';
    src: local('OpenSansItalic'), url(../fonts/OpenSans_Italic.ttf) format('truetype');
    /* other formats include: 'woff2', 'truetype, 'opentype',
                              'embedded-opentype', and 'svg' */
}

*{
    font-family: OpenSans !important;
}
